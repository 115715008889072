<template> 
  <div>
    <el-upload :action="dataObj.host"
               :data="dataObj"
               list-type="picture"
               :accept="accept"
               :multiple="false"
               :show-file-list="showFileList"
               :file-list="fileList"
               :before-upload="beforeUpload"
               :on-remove="handleRemove"
               :on-success="handleUploadSuccess"
               :on-preview="handlePreview">
      <!-- <el-button size="small"
                 type="primary"
                 style="float: right;">上传图片</el-button> -->
      <!--<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10MB</div>-->
      <img v-if="showImg"
           class="img_upload"
           src="@/assets/images/ic_shangchuan@2x.png">
      {{title}}
      <span v-if="remark"
            class="remark">点击上传或将表情图片拖动到此区域</span>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%"
           :src="fileList[0].url">
    </el-dialog>
  </div>
</template>
<script>
import ossApi from '@/api/apiModel/upload'

export default {
  name: 'singleUpload',
  props: {
    value: String,
    showList: {
      type: Boolean,
      default: true
    },
    showImg: {
      type: Boolean,
      default: false
    },
    title: String,
    remark: {
      type: Boolean,
      default: false
    },
    accept: {
        type: String,
        default: ''
      },
  },
  computed: {
    imageUrl () {
      return this.value;
    },
    imageName () {
      if (this.value != null && this.value !== '') {
        return this.value.substr(this.value.lastIndexOf("/") + 1);
      } else {
        return null;
      }
    },
    fileList () {
      return [{
        name: this.imageName,
        url: this.imageUrl
      }]
    },
    showFileList: {
      get: function () {
        return this.value !== null && this.value !== '' && this.value !== undefined && this.showList;
      },
      set: function (newValue) {
      }
    }
  },
  data () {
    return {
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
      },
      dialogVisible: false
    };
  },
  created () {
    this.getNum();
  },
  methods: {
    emitInput (val) {
      this.$emit('input', val)
    },
    handleRemove (file, fileList) {
      this.emitInput('')
    },
    handlePreview (file) {
      this.dialogVisible = true
    },
    beforeUpload (file) {
      let _self = this
      // 最大只能上传10M文件
      if (file.size > 10485760) {
        this.$message.error('上传失败，最大只能上传10M大小的图片')
        return false
      }
      return new Promise((resolve, reject) => {
        ossApi.policy().then(response => {
          _self.dataObj.policy = response.result.policy
          _self.dataObj.signature = response.result.signature
          _self.dataObj.ossaccessKeyId = response.result.accessKeyId
          // _self.dataObj.key = response.result.dir + '/' + file.uid + file.name
          _self.dataObj.key = response.result.dir + '/' + file.uid + _self.numGetInfo
          _self.dataObj.dir = response.result.dir
          _self.dataObj.host = response.result.host
          // debugger
          resolve(true)
        }).catch(err => {
          console.log(err)
          reject(false)
        })
      })
    },
    handleUploadSuccess (res, file) {
      this.showFileList = this.showList
      this.fileList.pop()
      this.fileList.push({
        // name: file.uid + file.name,
        // url: this.dataObj.host + '/' + this.dataObj.dir + '/' + file.uid + file.name
        name: file.uid + this.numGetInfo,
        url: this.dataObj.host + '/' + this.dataObj.dir + '/' + file.uid + this.numGetInfo
      })
      this.emitInput(this.fileList[0].url)
      let obj = {
        url:this.fileList[0].url,
        appendixName:file.name,
        appendixSize:file.size,
        appendixType: 1
        // appendixType: file.name.replace(/.+\./, "")
      }
      this.$emit('emitEmailInput',obj)
    },
    getNum () {
      do
        var out = Math.floor(Math.random() * 10000);
      while (out < 1000)
      this.numGetInfo = out;
    }
  }
}
</script>
<style scoped>
.img_upload {
  width: 32px;
  height: 32px;
}
.remark {
  font-size: 8px;
  color: #7a7c80;
}
</style>
